.page-header {
    transition: box-shadow 0.25s;

    &.-small {
        box-shadow: 0 -2px 10px 0px rgba(0, 0, 0, 0.5);
    }

    &__inner {
        position: relative;;
        height: 60px;

        @screen mp {
            height: 80px;
        }

        @screen l {
            height: 100px;
        }

        @screen lp {
            height: 120px;
        }

        @screen xl {
            height: 150px;
        }

        transition: height 0.25s;

        .-small & {
            height: 60px;

            @screen mp {
                height: 60px;
            }

            @screen l {
                height: 70px;
            }

            @screen lp {
                height: 80px;
            }

            @screen xl {
                height: 110px;
            }
        }
    }

    &__logo-wrap {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.-left {
            display: none;

            @screen mp {
                display: block;
                left: 0;
                z-index: 2;
            }

            .page-header:target & {
                @media (max-width: 980px) {
                    position: fixed;
                    top: 12px;
                    transform: none;
                    display: block;
                    left: 12px;
                    z-index: 99999999999;
                    opacity: 0.5;
                    path {
                        @apply fill-white;
                    }

                    @screen m {
                        left: 28px;
                    }

                    .page-header__logo {
                        font-size: 44px;
                    }
                }
            }

            &.-menu-open {
                @media (max-width: 980px) {
                    position: fixed;
                    top: 12px;
                    transform: none;
                    display: block;
                    left: 12px;
                    z-index: 99999999999;
                    opacity: 0.5;
                    path {
                        @apply fill-white;
                    }

                    @screen m {
                        left: 28px;
                    }

                    .page-header__logo {
                        font-size: 44px;
                    }
                }
            }
        }

        &.-right {
            left: 0;

            @screen mp {
                left: auto;
                right: 0;
                z-index: 2;
            }
        }
    }

    &__logo {
        font-size: 24px;
        display: block;
        line-height: 0;

        @screen l {
            font-size: 34px;
        }

        @screen lp {
            font-size: 40px;
        }

        @screen xl {
            font-size: 44px;
        }

        svg {
            width: 1em;
            height: 1.77625em;
        }

        transition: transform 0.25s;

        .-small & {
            transform: scale(0.8);
        }
    }

    &__logo-text {
        font-size: 20px;
        display: block;
        line-height: 0;

        @screen l {
            font-size: 24px;
        }

        @screen lp {
            font-size: 26px;
        }

        @screen xl {
            font-size: 28px;
        }

        svg {
            width: 5.8272em;
            height: 1em;
        }
    }
}
