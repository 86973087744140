.wysiwyg {
    b,
    strong {
        font-weight: bold;
    }

    em,
    i {
        font-style: italic;
    }

    a {
        color: #77bc4e;
        border-bottom: 1px solid transparent;
        transition: border-color 0.2s ease;

        &:hover {
            border-color: #77bc4e;
        }
    }

    h2 {
        font-weight: bold;
        margin-bottom: 5px;
    }

    ol,
    p,
    table,
    ul {
        margin-bottom: 1.5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: disc;
        padding-left: 25px;

        li {
            display: list-item;
            margin: 0;
        }
    }

    ol {
        list-style: decimal;
        padding-left: 25px;

        li {
            display: list-item;
            margin: 0;
        }
    }
}
