.front {
    padding: 0;
    position: relative;

    &__heading {
        z-index: 99999;
        font-weight: bold;
        
        @media (max-width: 600px) {
            position: fixed;
            font-size: 40px;
            top: 0;

            .front__col--left & {
                left: 15px;
                transform-origin: bottom left;
                transform: rotate(90deg) translateX(-25px);
            }

            .front__col--right & {
                left: 100%;
                transform-origin: bottom left;
                transform: rotate(90deg) translateX(-25px) translateY(55px);
            }
        }

        @screen sp {
            text-align: center;

            position: absolute;
            width: 100%;
            letter-spacing: 1px;
            font-size: 18px;
            bottom: -18px;
            left: 0;

            @media (min-height: 450px) {
                font-size: 4vh;
                bottom: -1vh;
            }
        }

        .front__col--left & {
            color: white;
        }

        .front__col--right & {
            color: black;
        }
    }

    &__col {
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 50%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        &--left {
            left: 0;
            color: black;

            @media (max-width: 600px) {
                background: currentColor;
            }
        }

        &--right {
            right: 0;
            color: white;
        }
    }

    &__video {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            .front__col--left & {
                background: white;
            }

            .front__col--right & {
                background: black;
            }

            @media (max-width: 600px) {
                opacity: 1 !important;
            }
        }

        video {
            display: none;

            @screen sp {
                display: block;
                max-width: none;
                transform: translateX(-50%);
                width: 100%;
                height: auto;
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__mask {
        width: calc(100vh * (1024/1615));
        max-width: 90vw;
        position: relative;
        flex: 0 0 auto;

        @media (max-width: 600px) {
            .front__col--left & {
                margin-left: 100%;
            }

            .front__col--right & {
                margin-right: 100%;
            }
        }

        @screen sp {
            width: calc(80vh * (1024/1615));
            max-width: 90%;

            &:hover {
                .front__mask-inner {
                    transform: scale(1.1);
                }
            }
        }

        &-inner {
            width: 100%;
            height: 0;
            padding-bottom: calc((1615/1024)*100%);
            position: relative;
            z-index: 2;
            transition: transform 0.5s;
        }

        &-bars {
            display: none;
            @screen sp {
                pointer-events: none;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                div {
                    position: absolute;
                    background: currentColor;
                    width: 51vw;
                    height: 100vh;

                    &:nth-of-type(odd) {

                    }

                    &:nth-of-type(1) {
                        left: 50%;
                        top: 1px;
                        transform: translateX(-50%) translateY(-100%);
                    }

                    &:nth-of-type(2) {
                        top: 0;
                        left: 99%;

                    }

                    &:nth-of-type(3) {
                        left: 50%;
                        bottom: 1px;
                        transform: translateX(-50%) translateY(100%);
                    }

                    &:nth-of-type(4) {
                        top: 0;
                        right: 99%;
                    }
                }
            }
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        path {
            fill: currentColor;
        }
    }
}
