@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */
    
    .spacing1 {
        margin-bottom: theme('gutterMap.s');

        @screen m {
            margin-bottom: theme('gutterMap.m');
        }

        @screen l {
            margin-bottom: theme('gutterMap.l');
        }

        @screen xl {
            margin-bottom: theme('gutterMap.xl');
        }
    }

    .spacing2 {
        margin-bottom: calc(theme('gutterMap.s') / 2);

        @screen m {
            margin-bottom: calc(theme('gutterMap.m') / 2);
        }

        @screen l {
            margin-bottom: calc(theme('gutterMap.l') / 2);
        }

        @screen xl {
            margin-bottom: calc(theme('gutterMap.xl') / 2);
        }
    }

    .spacing3 {
        margin-bottom: calc(theme('gutterMap.s') / 3);

        @screen m {
            margin-bottom: calc(theme('gutterMap.m') / 3);
        }

        @screen l {
            margin-bottom: calc(theme('gutterMap.l') / 3);
        }

        @screen xl {
            margin-bottom: calc(theme('gutterMap.xl') / 3);
        }
    }

    .top-pad {
        padding-top: theme('gutterMap.s');

        @screen m {
            padding-top: theme('gutterMap.m');
        }

        @screen l {
            padding-top: theme('gutterMap.l');
        }

        @screen xl {
            padding-top: theme('gutterMap.xl');
        }
    }

    .defaultTopOffset {
        padding-top: 80px;

        @screen m {
            padding-top: 130px;
        }
        @screen xl {
            padding-top: 200px;
        }
    }

    .defaultBlockSpacing {
        margin-bottom: 40px;
        
        @screen m {
            margin-bottom: 60px;
        }
        @screen l {
            margin-bottom: 80px;
        }
    }

}
