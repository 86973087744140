:root {
    --outer-margin: theme('outerMarginsMap.default');
    
    @screen s {
        --outer-margin: theme('outerMarginsMap.s');
    }
    @screen sp {
        --outer-margin: theme('outerMarginsMap.sp');
    }
    @screen m {
        --outer-margin: theme('outerMarginsMap.m');
    }
    @screen mp {
        --outer-margin: theme('outerMarginsMap.mp');
    }
    @screen l {
        --outer-margin: theme('outerMarginsMap.l');
    }
    @screen lp {
        --outer-margin: theme('outerMarginsMap.lp');
    }
    @screen xl {
        --outer-margin: theme('outerMarginsMap.xl');
    }
    
    --gutter: theme('gutterMap.default');
    
    @screen s {
        --gutter: theme('gutterMap.s');
    }
    @screen sp {
        --gutter: theme('gutterMap.sp');
    }
    @screen m {
        --gutter: theme('gutterMap.m');
    }
    @screen mp {
        --gutter: theme('gutterMap.mp');
    }
    @screen l {
        --gutter: theme('gutterMap.l');
    }
    @screen lp {
        --gutter: theme('gutterMap.lp');
    }
    @screen xl {
        --gutter: theme('gutterMap.xl');
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-out-cubic;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline,
html.no-outline * {
    outline: none !important;
}

body {
    padding-top: 60px;
    line-height: 1.3;

    @screen mp {
        padding-top: 80px;
    }

    @screen l {
        padding-top: 100px;
        font-size: 18px;
    }

    @screen lp {
        padding-top: 120px;
    }

    @screen xl {
        padding-top: 150px;
    }
}
