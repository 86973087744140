.menu {
    @media (max-width: 980px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: theme('colors.grey.dark');
        color: theme('colors.white');
        -webkit-font-smoothing: antialiased;
        z-index: 99999999;
        visibility: hidden;
        padding-top: 110px;

        .page-header:target & {
            visibility: visible;
        }
    }

    @screen mp {
        transition: line-height 0.25s;
        position: absolute;
        width: 100%;
        top: 0;
        line-height: 80px;
        z-index: 1;
    }

    @screen l {
        line-height: 100px;
    }

    @screen lp {
        line-height: 120px;
    }

    @screen xl {
        line-height: 150px;
    }

    .-small & {
        @screen mp {
            line-height: 60px;
        }

        @screen l {
            line-height: 70px;
        }

        @screen lp {
            line-height: 80px;
        }

        @screen xl {
            line-height: 110px;
        }
    }

    &__inner {
        @media (max-width: 980px) {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            overflow-y: scroll;
            padding-top: 110px;
        // -webkit-overflow-scrolling: touch;
        }
    }

    &__list {
        text-align: left;
        padding: 0 0 0 theme('outerMarginsMap.s');

        @screen m {
            padding: 0 0 0 theme('outerMarginsMap.m');
        }

        @screen mp {
            text-align: center;
            padding: 0;
            white-space: nowrap;
        }
    }

    &__item {
        font-size: 28px;
        line-height: 1;
        margin-bottom: 20px;
        position: relative;

        @screen mp {
            display: inline-block;
            font-size: 18px;
            margin: 0 12px;
        }

        @screen l {
            margin: 0 20px;
        }

        @screen lp {
            font-size: 20px;
        }
    }

    &__button-wrap {
        display: block;
        position: absolute;
        padding: 5px;

        &.-open {
            right: -5px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.-close {
            top: 17px;
            right: calc(theme('outerMarginsMap.s') - 5px);
            width: 30px;
            height: 26px;

            @screen m {
                width: 34px;
                height: 28px;
                right: calc(theme('outerMarginsMap.m') - 5px);
            }
        }

        @screen mp {
            display: none;
        }
    }

    &__open-line {
        display: block;
        width: 20px;
        height: 2px;
        background: #000;
        margin-bottom: 5px;

        @screen m {
            width: 25px;
            margin-bottom: 6px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__close-line {
        display: block;
        width: 20px;
        height: 2px;
        background: #fff;
        margin-bottom: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center center;

        @screen m {
            width: 25px;
            margin-bottom: 6px;
        }

        &:first-child {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__link {
        position: relative;
        
        @media (max-width: 980px) {
            opacity: 0.5;
            &.-active {
                opacity: 1;
            }
        }

        @screen mp {
            &:after {
                content: " ";
                position: absolute;
                width: 100%;
                bottom: 1px;
                height: 2px;
                background: #000;
                left: 0;
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.2s;
            }

            &.-active {
                &:after {
                    opacity: 1 !important;
                }
            }

            &:hover {
                &:after {
                    opacity: 0.3;
                }

                &.-has-sub-menu {
                    &:after {
                        opacity: 0;
                    }
                }
            }

            &:active {
                &:after {
                    opacity: 1;
                }
            }
        }

    }

    &__sub-menu {
        position: relative;
    }

    &__sub-items-wrap {
        height: 0;
        overflow: hidden;
        @screen mp {
            position: absolute;
            top: 0;
            left: -20px;
            transition: opacity 0.4s;
            opacity: 0;

            .menu__item:hover & {
                height: auto;
                opacity: 1;
            }
        }
    }

    &__sub-items {
        text-align: left;
        margin-left: theme('outerMarginsMap.s');
        padding-top: theme('outerMarginsMap.s');

        @screen m {
            margin-left: theme('outerMarginsMap.m');
        }

        @screen mp {
            margin-left: 0;
            background: #fff;
            padding: 40px 20px 30px;
        }
    }

    &__sub-item {
        opacity: 0.5;
        white-space: nowrap;
        display: block;
        margin-bottom: 0.5em;

        @screen mp {
            opacity: 1;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__sub-arrow {
        margin-left: 5px;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform-origin: center center;
        transform: translateY(-4px) rotateZ(-45deg);
        transition: transform 0.2s;

        @screen mp {
            border-color: #000;

            .menu__item:hover & {
                transform: rotateX(180deg) rotateZ(-45deg);
            }
        }

        .-is-open & {
            transform: rotateX(180deg) rotateZ(-45deg);
        }
    }
}
