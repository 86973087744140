.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: rgba(0, 0, 0, 0.95);
    display: none;
    z-index: 99999;
    opacity: 0;

    &__content {
        padding-top: theme('gutterMap.l');
        max-width: 1200px;
        margin: 0 auto;
    }

    &__close {
        display: block;
        position: absolute;
        padding: 5px;
        top: 17px;
        right: calc(theme('outerMarginsMap.s') - 5px);
        width: 30px;
        height: 26px;

        @screen m {
            width: 34px;
            height: 28px;
            right: calc(theme('outerMarginsMap.m') - 5px);
        }

    }

    &__close-line {
        display: block;
        width: 20px;
        height: 2px;
        background: #fff;
        margin-bottom: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center center;

        @screen m {
            width: 25px;
            margin-bottom: 6px;
        }

        &:first-child {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__loader {
        @apply outer-margins;

        position: absolute;
        z-index: 1;
        top: theme('gutterMap.l');
        width: 100%;
        max-width: 1200px;
        left: 50%;
        transform: translateX(-50%);

        svg {
            width: 50px;
            height: 89px;
        }

        path {
            fill: #fff;
        }
    }

    &__loader-outer {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
    }

    &__loader-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 89px;
        transform: translate(-50%, -50%);
    }
}
